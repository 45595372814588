/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import SEO from '../components/SEO/SEO'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { FormattedMessage } from 'react-intl'
import Link from 'gatsby-link'
import select from '../components/utils'
import menuTree from '../data/menuTree'

export const WorkPostTemplate = ({
    content,
    contentComponent,
    description,
    number,
    title,
    helmet,
    image,
    imageAlt,
    projectCity,
    projectTimeline,
    projectDate,
    projectFeatures,
    projectMembers,
    projectServices,
    projectTools,
    langFix,
    sel,
}) => {
    const PostContent = contentComponent || Content
    return (
        <section className="is-blog-section">
            {helmet || ''}
            <div className="container content">
                <div className="columns is-multiline">
                    <div className="column is-1 is-flex is-hidden-touch is-align-items-center">
                        <p className="is-size-4-rem has-text-weight-normal is-title-color">
                            {number}
                        </p>
                    </div>
                    <div className="column is-4">
                        <Img fluid={image} alt={imageAlt} />
                    </div>
                    <div className="column is-font-secondary has-text-dark is-offset-1 is-6 is-flex is-align-items-center">
                        <p>{description}</p>
                    </div>
                    <div className="column is-12">
                        <h2 className="is-size-4-rem is-size-1-mobile is-title-color has-text-weight-normal is-margin-bottom-less">
                            {title}
                        </h2>
                    </div>
                    <div className="column is-5 is-font-secondary">
                        <div className="mb-4">
                            <span className="has-text-weight-medium">
                                <FormattedMessage id="work.post.location" />
                            </span>
                            <p className="has-text-dark">
                                <PostContent content={projectCity} />
                            </p>
                        </div>
                        <div className="mb-4">
                            <span className="has-text-weight-medium">
                                <FormattedMessage id="work.post.date" />
                            </span>
                            <p className="has-text-dark">
                                <PostContent content={projectDate} />
                            </p>
                        </div>
                        <div className="mb-4">
                            <span className="has-text-weight-medium">
                                <FormattedMessage id="work.post.timeline" />
                            </span>
                            <p className="has-text-dark">
                                <PostContent content={projectTimeline} />
                            </p>
                        </div>

                        <div className="mb-4">
                            <span className="has-text-weight-medium">
                                <FormattedMessage id="work.post.team" />
                            </span>
                            <p className="has-text-dark">
                                <PostContent content={projectMembers} />
                            </p>
                        </div>

                        <div className="mb-4">
                            <span className="has-text-weight-medium">
                                <FormattedMessage id="work.post.features" />
                            </span>
                            <p className="has-text-dark">
                                <PostContent content={projectFeatures} />
                            </p>
                        </div>

                        <div className="mb-4">
                            <span className="has-text-weight-medium">
                                <FormattedMessage id="work.post.services" />
                            </span>
                            <p className="has-text-dark">
                                <PostContent content={projectServices} />
                            </p>
                        </div>

                        <div className="mb-4">
                            <span className="has-text-weight-medium">
                                <FormattedMessage id="work.post.tools" />
                            </span>

                            <p className="has-text-dark">
                                <PostContent content={projectTools} />
                            </p>
                        </div>
                    </div>
                    <div className="column is-offset-1 is-6 is-font-secondary">
                        <PostContent content={content} />
                    </div>

                    <div className="column is-12 has-text-centered is-size-1 mb-6">
                        <Link to={langFix + '/' + menuTree.hire[sel] + '/'}>
                            <h3 className="has-text-weight-normal has-text-primary has-underline-from-center-cta is-size-1">
                                <FormattedMessage id="work.post.hireus" />
                            </h3>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

WorkPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    helmet: PropTypes.object,
    tags: PropTypes.array,
    langKey: PropTypes.string,
    image: PropTypes.object,
    date: PropTypes.string,
    data: PropTypes.object,
    number: PropTypes.string,
    imageAlt: PropTypes.string,
    projectTimeline: PropTypes.string,
    projectCity: PropTypes.string,
    projectDate: PropTypes.string,
    projectFeatures: PropTypes.string,
    projectMembers: PropTypes.string,
    projectServices: PropTypes.string,
    projectTools: PropTypes.string,
}

const WorkPost = ({ data, location }) => {
    const { markdownRemark: post } = data
    const jsonData = data.allArticlesJson.edges[0].node.articles
    const langKey = post.frontmatter.lang
    const langFix = langKey === 'en' ? '' : 'ka'
    const image = post.frontmatter.image.childImageSharp.fluid
    const meta = data.site.siteMetadata
    const sel = select(langKey)

    return (
        <Layout
            className="container"
            data={data}
            jsonData={jsonData}
            location={location}
        >
            <SEO frontmatter={post.frontmatter} />
            <WorkPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                image={image}
                imageAlt={post.frontmatter.imageAlt}
                projectTimeline={post.frontmatter.projectTimeline}
                projectDate={post.frontmatter.projectDate}
                projectCity={post.frontmatter.projectCity}
                projectMembers={post.frontmatter.projectMembers}
                projectServices={post.frontmatter.projectServices}
                projectTools={post.frontmatter.projectTools}
                projectFeatures={post.frontmatter.projectFeatures}
                helmet={
                    <Helmet titleTemplate={`%s - ${meta.title}`}>
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                slug={post.frontmatter.slug}
                number={post.frontmatter.number}
                langKey={langKey}
                sel={sel}
                langFix={langFix}
            />
        </Layout>
    )
}

WorkPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
        allArticlesJson: PropTypes.object,
        site: PropTypes.object,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default WorkPost

export const pageQuery = graphql`
    query WorkPostByID($id: String!) {
        site {
            siteMetadata {
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                slug
                id
                title
                number
                projectTimeline
                projectDate
                projectCity
                projectMembers
                projectServices
                projectTools
                projectFeatures
                imageAlt
                image {
                    childImageSharp {
                        fluid(maxWidth: 1380) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                description
                lang
            }
        }
    }
`
